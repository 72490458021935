<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-content-dialog>
      <DialogTitle @close="$emit('close')" title="Configure List" />
      <v-card-text>
        <v-form ref="form">
          <v-tabs v-model="tabModel">
            <v-tab aut-tab-default>Display</v-tab>
            <v-tab ref="config" aut-tab-look>Look and Feel</v-tab>
            <v-tab aut-tab-data-api>Data API</v-tab>
            <v-tab aut-tab-actions>Actions</v-tab>
            <v-tab aut-tab-permissions v-if="nonEditorPermissionsAllowed"
              >Permissions</v-tab
            >
          </v-tabs>
          <v-tabs-items v-model="tabModel">
            <v-tab-item>
              <v-row dense>
                <v-col cols="12">
                  <v-radio-group
                    v-model="listDefinition.display.mode"
                    label="List Mode"
                    dense
                    row
                    persistent-hint
                    hint="This controls how the list's contents are displayed"
                  >
                    <v-radio
                      :aut-edit-list-mode="option.value"
                      v-for="(option, i) in toggleValues"
                      :key="i"
                      :label="option.label"
                      :value="option.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="listDefinition.definition.mode"
                    label="Mode"
                    dense
                    row
                    persistent-hint
                    hint="This controls how the list's fields are displayed (if they don't specify a mode themselves)"
                  >
                    <v-radio
                      :aut-edit-list-mode="option.value"
                      v-for="(option, i) in modes"
                      :key="i"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-radio-group
                    v-model="listDefinition.display.width"
                    label="Width"
                    dense
                    row
                    persistent-hint
                    hint="This controls how much width the list takes up"
                  >
                    <v-radio
                      :aut-edit-list-width="option.value"
                      v-for="(option, i) in widths"
                      :key="i"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="showBulkOperations">
                  <v-switch
                    dense
                    aut-edit-list-bulk-operation
                    name="bulk_operation"
                    v-model="listDefinition.definition.bulk_operation"
                    label="Support Bulk Operation"
                    persistent-hint
                    hint="Should the user be able to select multiple records"
                    @change="toggleBulkOperation"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Toggles"
                    hint="Choose toggles to be displayed"
                    persistent-hint
                    aut-toggles
                    :multiple="true"
                    v-model="listDefinition.display.attributes.toggles"
                    :items="toggleValues"
                    item-text="label"
                    item-value="id"
                  >
                    <tutorial-trigger
                      slot="append-outer"
                      tutorial="list_toggles"
                    />
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row dense>
                  <v-col>
                    <FieldSettings
                      v-if="listTypeDefinition"
                      :preview="false"
                      :field="listTypeDefinition"
                      :definition.sync="listDefinition"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <APIDesigner
                :definition.sync="listDefinition.apis.data"
                type="data"
                @remove_api="removeData()"
              />
            </v-tab-item>
            <v-tab-item>
              <ActionsDesigner
                :key="actionKey"
                :isList="!!listDefinition.definition.bulk_operation"
                :definition.sync="listDefinition.definition.actions"
                @update:definition="updateActions"
              />
            </v-tab-item>
            <v-tab-item v-if="nonEditorPermissionsAllowed">
              <PermissionNode :permissions.sync="listDefinition._permissions" />
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn aut-action-edit-list-cancel text @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          aut-action-edit-content-update
          color="primary"
          @click="updateDefinition"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { mapActions } from "vuex";

import { defaultsDeep } from "lodash";
import { contentModes, widths, clone } from "@/util.js";

import Actions from "@/components/fields/Actions";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";
import FieldSettings from "@/components/fields/Field/FieldSettings/FieldSettings.vue";
import listTypeDefinition from "@/components/pageContent/List/definition";

const debug = require("debug")("atman.components.edit_table_dialog"); // eslint-disable-line

export default {
  name: "EditListDialog",
  mixins: [dialogMixin],
  created() {
    this.modes = contentModes;
    this.widths = widths;
    this.toggleValues = [
      {
        id: "table",
        label: "Table",
      },
      {
        id: "cards",
        label: "Cards",
      },
      {
        id: "list",
        label: "List",
      },
      {
        id: "slider",
        label: "Slider",
      },
    ];
  },
  mounted() {
    debug(`In mounted of EditListDialog`);
    this.createListDefinition();
  },
  props: {
    definition: {
      type: Object,
    },
  },
  components: {
    ActionsDesigner: Actions.designer,
    APIDesigner,
    FieldSettings,
    PermissionNode: () => import("@/components/editor/PermissionNode"),
  },
  data() {
    const listDefinition = defaultsDeep({}, this.definition, {
      permissions: {
        enabled: false,
      },
      apis: {
        data: {
          url: "",
          type: "get",
        },
      },
      display: {
        mode: "table",
        width: "12",
        attributes: {
          toggles: [],
        },
      },
      definition: {
        actions: [],
      },
    });
    return {
      listTypeDefinition: null,
      display: true,
      tabModel: null,
      listDefinition,
      actionKey: 1,
    };
  },
  computed: {
    nonEditorPermissionsAllowed() {
      return this.isFeatureEnabled("permissions.non_editor.enabled");
    },
    showBulkOperations() {
      return this.isFeatureEnabled("bulk_operations");
    },
  },
  watch: {
    "listDefinition.display.mode"() {
      this.createListDefinition();
    },
  },
  methods: {
    ...mapActions(["show_tutorial"]),
    createListDefinition() {
      const component = this;
      component.listTypeDefinition = null;
      const mode = component.listDefinition.display.mode || "table";
      debug(`listTypeDefinition`, mode, listTypeDefinition);
      let definition = clone(listTypeDefinition);
      definition.attributes = definition.attributes.filter((attribute) => {
        if (!attribute.mode) {
          return true;
        }
        return attribute.mode == mode;
      });
      component.$nextTick(() => {
        component.listTypeDefinition = clone(definition);
      });
    },
    toggleBulkOperation() {
      const component = this;
      if (!component.listDefinition.definition.bulk_operation) {
        component.listDefinition.definition.actions.forEach((action) => {
          delete action.bulk_operation;
        });
        component.listDefinition.definition.fields.forEach((field) => {
          delete field.bulk_operation;
        });
      }
      component.actionKey++;
    },
    updateDefinition() {
      debug(`updated definition:`, this.listDefinition);
      if (!this.listDefinition.definition.bulk_operation) {
        delete this.listDefinition.definition.bulk_operation;
      }
      this.$emit("update:definition", this.listDefinition);
      this.$emit("close");
    },
    updateActions(actions) {
      debug(`updateActions invoked`, actions);
      this.listDefinition.definition.actions = actions;
    },
    removeData() {
      this.listDefinition.apis.data.type = "";
      this.listDefinition.apis.data.url = "";
      debug(this.listDefinition);
    },
  },
};
</script>
